import React from 'react';

import { SnippetsWrapperCss, SnippetPostWrapperCss } from './index.css';
import SnippetPost from './SnippetPost';

const Snippets = ({ posts }) => {
  return (
    <SnippetsWrapperCss>
      <h1>Snippets</h1>
      <h5>
        A collection of useful bits of code that can be easily integrated with
        any project
      </h5>
      <SnippetPostWrapperCss>
        {posts.map(post => (
          <SnippetPost postData={post.node} key={post.node.id} />
        ))}
      </SnippetPostWrapperCss>
    </SnippetsWrapperCss>
  );
};

export default Snippets;
