import React from 'react';
import Layout from 'components/layout';
import SnippetsComponent from 'components/Snippets';
import { graphql } from 'gatsby';
import SEO from '../utils/seo';

const Snippets = ({ data }) => {
  const posts = data.allMdx.edges;
  return (
    <Layout>
      <SEO title="Snippets" />
      <SnippetsComponent posts={posts} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SnippetsIndexQuery {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { ne: true }, type: { in: ["snippets"] } }
      }
    ) {
      edges {
        node {
          id
          body
          excerpt(pruneLength: 180, truncate: true)
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM/DD/YYYY")
            title
            tags
            type
            excerpt
            thumbnail
            draft
          }
        }
      }
    }
  }
`;

export default Snippets;
