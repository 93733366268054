import styled from 'styled-components';

export const SnippetsWrapperCss = styled.div`
  padding: 100px 0;

  h1 {
    font-size: 42px;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 20;
    margin-bottom: 50px;
  }
`;

export const SnippetPostWrapperCss = styled.div`
  align-items: center;
  background-repeat: repeat;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 25px;
`;

// POSTS
export const PostContentCss = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PostWrapperCss = styled.div`
  text-decoration: none;
  color: #000;
  background: ${props => props.color};

  cursor: pointer;
  height: 200px;
  width: 200px;
  padding: 30px 15px 15px;
  margin: 15px 15px 50px;
  -moz-box-shadow: 5px 5px 7px rgba(33, 33, 33, 1);
  -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  -moz-transition: -moz-transform 0.15s linear;
  -o-transition: -o-transform 0.15s linear;
  -webkit-transition: -webkit-transform 0.15s linear;
  transition: -webkit-transform 0.15s linear;
  -moz-box-shadow: 5px 5px 7px rgba(33, 33, 33, 1);
  /* Safari+Chrome */
  -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  /* Opera */
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  -o-transform: rotate(-1deg);
  -webkit-transform: rotate(-1deg);
  -moz-transform: rotate(-1deg);
  transform: rotate(-1deg);
  position: relative;
  top: 5px;

  &:nth-child(even) {
    -o-transform: rotate(4deg);
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    transform: rotate(4deg);
    position: relative;
    top: 5px;
  }
  &:nth-child(3n) {
    -o-transform: rotate(-4deg);
    -webkit-transform: rotate(-4deg);
    -moz-transform: rotate(-4deg);
    transform: rotate(-4deg);
    position: relative;
    top: -5px;
  }
  &:nth-child(5n) {
    -o-transform: rotate(5deg);
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
    position: relative;
    top: -10px;
  }
  &:nth-child(7n) {
    -o-transform: rotate(1deg);
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg);
    transform: rotate(1deg);
    position: relative;
    top: 0px;
  }

  &:hover,
  &:focus {
    -moz-box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
    box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.25);
    position: relative;
    z-index: 5;
  }
`;

export const TagWrapperCss = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  background: white;
  padding: 3px 6px;
  transform: rotate(20deg);
  transform-origin: 100% 0;
  box-shadow: 2px 2px 3px -2px black;

  img {
    position: absolute;
    transform: rotate(-40deg);
    top: -9px;
    right: 10px;
    width: 20px;
  }
`;

export const PostTitleCss = styled.h2`
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.2;
`;
export const PostDescriptionCss = styled.h2`
  font-size: 26px;
  font-family: 'Reenie', arial, sans-serif;
`;
