import React from 'react';
import { Link } from 'gatsby';
import {
  PostContentCss,
  TagWrapperCss,
  PostTitleCss,
  PostDescriptionCss,
  PostWrapperCss,
} from './index.css';
import { generateColor } from '../../helpers/generateColor';
import PaperClip from '../../images/snippets/paper-clip.png';

const SnippetPost = ({ postData }) => {
  return (
    <PostWrapperCss color={generateColor()}>
      <Link to={postData.fields.slug}>
        <PostContentCss>
          <TagWrapperCss>
            <img src={PaperClip} alt="paper-clip" />
            {postData.frontmatter.tags[0]}
          </TagWrapperCss>
          <PostTitleCss>{postData.frontmatter.title}</PostTitleCss>
          <PostDescriptionCss>
            {postData.frontmatter.excerpt}
          </PostDescriptionCss>
        </PostContentCss>
      </Link>
    </PostWrapperCss>
  );
};

export default SnippetPost;
